<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="4">
          <label style="font-size: 14px; margin-bottom: 7px" for="customer">
            {{ $t("code") }}
          </label>
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            name="code"
          />
        </b-col>

        <b-col md="4">
          <!-- arabicName  -->
          <g-field
            rules="required"
            id="arabicName"
            ref="arabicName"
            :value.sync="selectedItem.arabicName"
            name="arabicName"
            label-text="arabicName"
          />
        </b-col>
        <b-col md="4">
          <g-field
            id="englishName"
            ref="englishName"
            :value.sync="selectedItem.englishName"
            name="englishName"
            label-text="englishName"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          v-show="!selectedItem.isAdmin"
          md="5"
          class="medium-window"
        >
          <permissions
            ref="perComponent"
            :key="selectedItem.id"
            :user-permissions="selectedItem.permissions"
            @update-items="
              (val) => {
                items = val;
              }
            "
          />
        </b-col>
        <b-col md="3">
          <label style="font-size: 14px; margin-bottom: 7px" for="general">
            {{ $t("general") }}
          </label>
          <b-form-group>
            <b-form-checkbox
              v-model="selectedItem.isShared"
              class="mr-0 mt-50"
              name="is-rtl"
              inline
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- notes -->
        <b-col md="12">
          <b-form-group>
            <label style="font-size: 14px; margin-bottom: 7px" for="customer">
              {{ $t("notes") }}
            </label>
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
            v-permission="$route.meta.permission"
          >
            {{ selectedItem.id > 0 ? $t("edit") : $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import permissions from '../Users/components/Permissions.vue';

export default {
  components: {
    permissions,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      selectedItem: {
        permissions: [],
        arabicName: '',
        englishName: ''
      },
      Policies: [],
    };
  },
  mounted() {
    // this.getPolicies();
    if (this.id) {
      this.getItems();
    }
  },
  methods: {
    getItems() {
      this.get({ url: 'accessGroups', id: this.id }).then((data) => {
        this.selectedItem = data;
      });
    },
    getPolicies() {
      this.get({ url: 'Policies' }).then((data) => {
        this.Policies = data;
      });
    },
    save() {
      if (this.selectedItem.permissions) {
        this.selectedItem.permissions = this.items.reduce(
          (groupPermissions, permission) => {
            if (permission.selected) {
              groupPermissions.push(
                {
                  userId: this.id,
                  permissionName: permission.name,
                }
              );
            }
            return groupPermissions;
          },
          []
        );
      } else {
        this.selectedItem.permissions = [];
      }
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'accessGroups',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.$router.push({ name: 'teamWork' });
        });
      } else {
        this.create({ url: 'accessGroups', data: this.selectedItem }).then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.$router.push({ name: 'teamWork' });
        });
      }
    },
  },
};
</script>

<style></style>
